/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import { Row, Col } from 'antd';
import asset5 from '../assets/Asset 5.svg';
import { TwitterOutlined, LinkedinOutlined, YoutubeOutlined, InstagramOutlined, FacebookOutlined } from '@ant-design/icons';

import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./layout.css"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <div style={{ minHeight: '100vh', background:'#002140' }}> {/*#006DB6*/}
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        {children}
      </main>
      <footer style={{ textAlign: 'center', background:'#002140', paddingTop:'20px' }}>
        <Row justify="center" style={{color:'hsla(0,0%,100%,.5)', marginTop:'15px', width:'100%'}}>
          <Col sm={14} lg={7} style={{textAlign:'left', padding:'0px 20px'}}>
            <Link to='/' ><img src={asset5} alt='rsLogo' style={{width:'40px', justifyContent:'center'}}/></Link>
            <p style={{fontSize:'30px', marginTop:'15px'}}>
              <YoutubeOutlined style={{padding:'15px 15px 15px 0px'}} />
              <TwitterOutlined style={{padding:'15px'}} />
              <LinkedinOutlined style={{padding:'15px'}} />
              <InstagramOutlined style={{padding:'15px'}} />
              <FacebookOutlined style={{padding:'15px'}} />
            </p>
            <p>RealtyStack ©2020</p>
          </Col>
          <Col sm={14} lg={14} style={{textAlign:'left', padding:'0px 20px'}}>
            <Row>
              <Col sm={14} lg={6} style={{textAlign:'left', padding:'0px 20px'}}>
                <p style={{color:'#fff'}}>Features</p>
                <ul style={{listStyle:'none', paddingLeft:'0px'}}>
                  <li><Link style={{color:'#ffffff80'}} to='/features/#payments'>Payments</Link></li>
                  <li><Link style={{color:'#ffffff80'}} to='/features/#management'>Management</Link></li>
                  <li><Link style={{color:'#ffffff80'}} to='/features/#residents'>Residents</Link></li>
                  <li><Link style={{color:'#ffffff80'}} to='/features/'>Communication</Link></li>
                  <li><Link style={{color:'#ffffff80'}} to='/features/'>Reports</Link></li>
                </ul>
              </Col>
              <Col sm={14} lg={6} style={{textAlign:'left', padding:'0px 20px'}}>
                <p style={{color:'#fff'}}>Company</p>
                <ul style={{listStyle:'none', paddingLeft:'0px'}}>
                  <li><Link style={{color:'#ffffff80'}} to='/company/about/'>About</Link></li>
                  <li><Link style={{color:'#ffffff80'}} to='/company/contact/'>Contact</Link></li>
                  {/* <li>Careers</li> */}
                </ul>
              </Col>
              <Col sm={14} lg={6} style={{textAlign:'left', padding:'0px 20px'}}>
                <p style={{color:'#fff'}}>Resources</p>
                <ul style={{listStyle:'none', paddingLeft:'0px'}}>
                  <li><Link style={{color:'#ffffff80'}} to='/support/faq/'>FAQs</Link></li>
                  <li><Link style={{color:'#ffffff80'}} to='/support/start/'>Support</Link></li>
                  <li><a style={{color:'#ffffff80'}} href={process.env.GATSBY_REALTYSTACK_BLOG_URL} target='_blank'>Blog</a></li>
                  {/* <li>Newsletter</li> */}
                </ul>
              </Col>
              <Col sm={14} lg={6} style={{textAlign:'left', padding:'0px 20px'}}>
                <p style={{color:'#fff'}}>Legal</p>
                <ul style={{listStyle:'none', paddingLeft:'0px'}}>
                  {/* <li><Link style={{color:'#ffffff80'}} to='/legal/security/'>Security</Link></li> */}
                  <li><Link style={{color:'#ffffff80'}} to='/legal/terms/'>Terms of Service</Link></li>
                  <li><Link style={{color:'#ffffff80'}} to='/legal/privacy/'>Privacy Policy</Link></li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
