import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import asset26 from '../assets/Asset 26.svg';
import asset5 from '../assets/Asset 5.svg';
import { Menu, Row, Col, Button, Popover, List, Avatar, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { isMobile, isSafari } from "react-device-detect";
 
const featuresData = [
  { title: 'Rent Collections', link: '/features/', icon: 'dollar'},
  { title: 'Reports', link: '/features/', icon: 'solution'},
  { title: 'Payments', link: '/features/', icon: 'credit-card'},
  { title: 'Organization', link: '/features/', icon: 'apartment'},
];

const companyData = [
  { title: 'About', link: '/faq/', icon: 'book'},
  { title: 'Contact', link: '/faq/', icon: 'phone'},
  { title: 'Careers', link: '/faq/', icon: 'apartment'},
];

const resourcesData = [
  { title: 'Frequently Asked Questions (FAQs)', link: '/support/help-center/faq/', icon: 'question'},
  { title: 'Help Center', link: '/support/help-center/', icon: 'customer-service'},
  { title: 'Blog', link: '/blog/', icon: 'read'},
  { title: 'Newsletter', link: '/newsletter/', icon: 'mail'},
];

const featuresContent = data => (
  <div style={{padding:'10px', minWidth:'230px'}}>
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={item => (
        <List.Item
        style={{border:'none'}}
        >
          <List.Item.Meta
          // avatar={<Avatar size={28} style={{background:'none', color:'#0069ff', border:'1px solid #0069ff'}} icon={item.icon} />}
          title={<span>{item.title}</span>}
          />
        </List.Item>
      )}
    />
  </div>
);

const Header = () => {
  const [isTop, setIsTop] = useState(true)
  const [showNav, setShowNav] = useState(true)
  const [scrollPosition, setScrollPosition] = useState(0);
  const [mobileLayout, setMobileLayout] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [width, setWidth] = useState(typeof window !== `undefined` ? window.innerWidth : 1000)

  const handleScroll = () => {
    let scrollYPosition = window.pageYOffset
    setIsTop(scrollYPosition < 30);

    if (scrollPosition > scrollYPosition) {
        setShowNav(true)
    } else if (scrollPosition < scrollYPosition) {
        setShowNav(false)
    }
    setScrollPosition(scrollYPosition);
  };

  useEffect(() => {      
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition, setScrollPosition]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setMobileLayout(window.innerWidth <= 760)
    }
    window.addEventListener("resize", handleResize)
    return () => { window.removeEventListener("resize", handleResize) }
  }, [])


  return (
  <header
  style={{ position: 'fixed', 
  zIndex: 1, 
  width: '100%', 
  display:'inline-flex', 
  background: isTop ? '#002140' : showNav ? '#002140' : isSafari ? '#002140' : 'none', //'#005cb9', 
  transition: 'all .2s ease',
  transform: showNav ? 'translateY(0)' : isSafari ? 'translateY(0)' : 'translateY(-100%)',
  boxShadow: isTop ? 'none' : showNav ? '0 8px 6px -6px #1c1a1a' : 'none', 
  }}
  >
    <Row style={{width:'100%'}} justify='center'>
      <Col xs={22} sm={22} md={18} lg={16} style={{display:'inline-flex'}}>
        <Link to='/' style={{display:'inherit'}}>
          <img className="logo" alt="rsLogo" src={asset5}
          style={{ height:'32px', marginTop:'15px', marginBottom:'6px', marginRight:'15px', verticalAlign:'top',}} 
          />
          <img className="logo" alt="realtystackLogo" src={asset26}
          style={{ height:'32px', marginTop:'15px', marginBottom:'6px', marginRight:'15px', verticalAlign:'top',}} 
          />
        </Link>
          { isMobile || mobileLayout ? 
          <div 
          style={{ lineHeight: '64px', width:'100%', fontWeight:'500', fontSize:'16px', background:'inherit' }}
          >
            <Button type="primary" onClick={() => setShowMobileMenu(true)} style={{marginTop:'10px', float: 'right', border:'none', background: 'rgb(67, 196, 99)'}}>
              <MenuOutlined />
            </Button>
            <Drawer
            title={
              <Row justify='center'>
                  <a href={`${process.env.GATSBY_REALTYSTACK_APP_URL}/login/`} style={{float:'right', marginRight:'20px', color:'#fff'}}>
                  <Button 
                  size='large' 
                  type='link' 
                  style={{color:'#fff'}} 
                  // onMouseEnter={() => }
                  >
                    Login
                  </Button>
                  </a>
                  <a href={`${process.env.GATSBY_REALTYSTACK_APP_URL}/signup/`} style={{float:'right'}}>
                  <Button 
                  id='home-signup-button' 
                  size='large' 
                  type='primary' 
                  style={{color:'#fff', border:'1px solid rgb(67, 196, 99)'}} 
                  ghost
                  // onMouseEnter={() => }
                  >
                  Signup
                  </Button>
                  </a>
              </Row>
            }
            placement="right"
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            onClose={() => setShowMobileMenu(false)}
            visible={showMobileMenu}
            bodyStyle={{padding:'0px', backgroundColor:'#002140'}}
            headerStyle={{borderBottom:'none', color:'#fff', backgroundColor: '#002140'}}
            >
              <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={['']}
              style={{ lineHeight: '64px', width:'100%', fontWeight:'500', fontSize:'16px', background:'inherit', paddingLeft:'30px' }}
              >
                <Menu.Item key="1">
                  <Popover placement='bottom' content={featuresContent(featuresData)} style={{transitionProperty:'transform,opacity,-webkit-transform', transitionDuration:'.25s', transform:'translateX(-150px)'}}>
                    {/* <Link to='/features/'>Features</Link> */}
                    {/* <span>Features</span> */}
                  </Popover>
                </Menu.Item>
                <Menu.Item key="3">
                  <Popover placement='bottom' content={featuresContent(companyData)}>
                    {/* <Link to='/'>Company</Link> */}
                  </Popover>
                </Menu.Item>
                <Menu.Item key="2">
                  {/* <Link to='/'>Pricing</Link> */}
                </Menu.Item>
                <Menu.Item key="4">
                  <Popover placement='bottom' content={featuresContent(resourcesData)}>
                    {/* <Link to='/support/'>Support</Link> */}
                  </Popover>
                </Menu.Item>
            </Menu>
          </Drawer>
          </div>
          :
          <Menu
          theme="dark"
          mode={ isMobile || mobileLayout ? 'vertical' : 'horizontal'}
          defaultSelectedKeys={['']}
          style={{ lineHeight: '64px', width:'100%', fontWeight:'500', fontSize:'16px', background:'inherit', paddingLeft:'30px' }}
          >
          <Menu.Item key="1">
            <Popover placement='bottom' content={featuresContent(featuresData)} style={{transitionProperty:'transform,opacity,-webkit-transform', transitionDuration:'.25s', transform:'translateX(-150px)'}}>
              {/* <Link to='/features/'>Features</Link> */}
              {/* <span>Features</span> */}
            </Popover>
          </Menu.Item>
          <Menu.Item key="3">
            <Popover placement='bottom' content={featuresContent(companyData)}>
              {/* <Link to='/'>Company</Link> */}
            </Popover>
          </Menu.Item>
          <Menu.Item key="2">
            {/* <Link to='/'>Pricing</Link> */}
          </Menu.Item>
          <Menu.Item key="4">
            <Popover placement='bottom' content={featuresContent(resourcesData)}>
              {/* <Link to='/support/'>Support</Link> */}
            </Popover>
          </Menu.Item>
            <a href={`${process.env.GATSBY_REALTYSTACK_APP_URL}/signup/`} style={{float:'right'}}>
              <Button 
              id='home-signup-button' 
              size='large' 
              type='primary' 
              style={{color:'#fff', border:'1px solid rgb(67, 196, 99)'}} 
              ghost
              // onMouseEnter={() => }
              >
              Signup
              </Button>
            </a>
            <a href={`${process.env.GATSBY_REALTYSTACK_APP_URL}/login/`} style={{float:'right', marginRight:'20px', color:'#fff'}}>
            <Button 
            size='large' 
            type='link' 
            style={{color:'#fff'}} 
            // onMouseEnter={() => }
            >
              Login
            </Button>
            </a>
        </Menu>
        }
      </Col>
    </Row>
  </header>
)
}

export default Header;

